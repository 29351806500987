var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Site Name")]), _c('p', [_vm._v(_vm._s(_vm.asset.siteName))])]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Address")]), _c('p', [_vm._v(_vm._s(_vm.asset.addressString))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Asset Type")]), _c('p', [_vm._v(_vm._s(_vm.asset.assetType))])]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Floor Area")]), _c('p', [_vm._v(" " + _vm._s(_vm.asset.floorArea) + " "), _vm.asset.floorAreaUnit === 'imperial' ? _c('span', [_vm._v("ft"), _c('sup', [_vm._v("2")])]) : _c('span', [_vm._v("m"), _c('sup', [_vm._v("2")])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Occupation Status")]), _c('p', {
    staticClass: "text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.asset.occupationStatus) + " ")])])])]), _c('div', {
    staticClass: "mb-4"
  }, [_vm._m(1), _c('div', {
    staticClass: "row"
  }, [_vm.epbRating ? _c('div', {
    staticClass: "col"
  }, [_c('strong', [_vm._v("EPC")]), _c('p', [_vm._v(_vm._s(_vm.epbRating))])]) : _vm._e(), _vm.breeamScore ? _c('div', {
    staticClass: "col"
  }, [_c('strong', [_vm._v("BREEAM")]), _c('p', [_vm._v(_vm._s(_vm.breeamScore))])]) : _vm._e(), _vm.wiredScore ? _c('div', {
    staticClass: "col"
  }, [_c('strong', [_vm._v("WiredScore")]), _c('p', [_vm._v(_vm._s(_vm.wiredScore))])]) : _vm._e()])]), _c('div', {
    staticClass: "mb-4"
  }, [_vm._m(2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_c('div', [_c('UtilityTypeIcon', {
    attrs: {
      "icon-class": "mr-2",
      "no-fw": "",
      "no-color": "",
      "text": false,
      "type": "electricity",
      "large": ""
    }
  })], 1), _c('div', {
    staticClass: "ml-2"
  }, [_c('strong', [_vm._v("Electricity")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.electricityAccounts.length) + " account" + _vm._s(_vm.electricityAccounts.length > 1 ? 's' : ''))])])])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_c('UtilityTypeIcon', {
    attrs: {
      "icon-class": "mr-2",
      "no-fw": "",
      "no-color": "",
      "text": false,
      "type": "gas",
      "large": ""
    }
  })], 1), _c('div', {
    staticClass: "ml-2"
  }, [_c('strong', [_vm._v("Gas")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.gasAccounts.length) + " account" + _vm._s(_vm.gasAccounts.length > 1 ? 's' : ''))])])])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_c('div', [_c('UtilityTypeIcon', {
    attrs: {
      "icon-class": "mr-2",
      "no-fw": "",
      "no-color": "",
      "text": false,
      "type": "water",
      "large": ""
    }
  })], 1), _c('div', {
    staticClass: "ml-2"
  }, [_c('strong', [_vm._v("Water")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.waterAccounts.length) + " account" + _vm._s(_vm.waterAccounts.length > 1 ? 's' : ''))])])])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_c('UtilityTypeIcon', {
    attrs: {
      "icon-class": "mr-2",
      "no-fw": "",
      "no-color": "",
      "text": false,
      "type": "solar",
      "large": ""
    }
  })], 1), _c('div', {
    staticClass: "ml-2"
  }, [_c('strong', [_vm._v("Solar")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.solarAccounts.length) + " account" + _vm._s(_vm.solarAccounts.length > 1 ? 's' : ''))])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "text-secondary"
  }, [_c('i', {
    staticClass: "fa text-primary fa-building mr-2"
  }), _vm._v("Summary")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_c('i', {
    staticClass: "fa text-primary fa-certificate mr-2"
  }), _vm._v("Certificates")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_c('i', {
    staticClass: "fa text-primary fa-meter-bolt mr-2"
  }), _vm._v("Utilities")]);

}]

export { render, staticRenderFns }