<template>
  <div>
    <div class="mb-4">
      <h3 class="text-secondary"><i class="fa text-primary fa-building mr-2" />Summary</h3>
      <div class="row">
        <div class="col-6">
          <strong>Site Name</strong>
          <p>{{ asset.siteName }}</p>
        </div>
        <div class="col-6">
          <strong>Address</strong>
          <p>{{ asset.addressString }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <strong>Asset Type</strong>
          <p>{{ asset.assetType }}</p>
        </div>
        <div class="col-6">
          <strong>Floor Area</strong>
          <p>
            {{ asset.floorArea }} <span v-if="asset.floorAreaUnit === 'imperial'">ft<sup>2</sup></span
            ><span v-else>m<sup>2</sup></span>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <strong>Occupation Status</strong>
          <p class="text-capitalize">
            {{ asset.occupationStatus }}
          </p>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h3><i class="fa text-primary fa-certificate mr-2" />Certificates</h3>
      <div class="row">
        <div v-if="epbRating" class="col">
          <strong>EPC</strong>
          <p>{{ epbRating }}</p>
        </div>
        <div v-if="breeamScore" class="col">
          <strong>BREEAM</strong>
          <p>{{ breeamScore }}</p>
        </div>
        <div v-if="wiredScore" class="col">
          <strong>WiredScore</strong>
          <p>{{ wiredScore }}</p>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h3><i class="fa text-primary fa-meter-bolt mr-2" />Utilities</h3>
      <div class="row">
        <div class="col">
          <div class="d-flex align-items-center mb-3">
            <div><UtilityTypeIcon icon-class="mr-2" no-fw no-color :text="false" type="electricity" large /></div>
            <div class="ml-2">
              <strong>Electricity</strong>
              <p class="mb-0">{{ electricityAccounts.length }} account{{ electricityAccounts.length > 1 ? 's' : '' }}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="d-flex align-items-center">
            <div><UtilityTypeIcon icon-class="mr-2" no-fw no-color :text="false" type="gas" large /></div>
            <div class="ml-2">
              <strong>Gas</strong>
              <p class="mb-0">{{ gasAccounts.length }} account{{ gasAccounts.length > 1 ? 's' : '' }}</p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="d-flex align-items-center mb-3">
            <div><UtilityTypeIcon icon-class="mr-2" no-fw no-color :text="false" type="water" large /></div>
            <div class="ml-2">
              <strong>Water</strong>
              <p class="mb-0">{{ waterAccounts.length }} account{{ waterAccounts.length > 1 ? 's' : '' }}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="d-flex align-items-center">
            <div><UtilityTypeIcon icon-class="mr-2" no-fw no-color :text="false" type="solar" large /></div>
            <div class="ml-2">
              <strong>Solar</strong>
              <p class="mb-0">{{ solarAccounts.length }} account{{ solarAccounts.length > 1 ? 's' : '' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UtilityTypeIcon from '@/components/UtilityTypeIcon';

export default {
  name: 'AssetDetailsPage',
  components: {
    UtilityTypeIcon
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    }
  },
  computed: {
    gasAccounts() {
      return this.accounts.filter(a => a.type === 'gas');
    },
    waterAccounts() {
      return this.accounts.filter(a => a.type === 'water');
    },
    electricityAccounts() {
      return this.accounts.filter(a => a.type === 'electricity');
    },
    solarAccounts() {
      return this.accounts.filter(a => a.type === 'solar');
    },
    epbRating() {
      const cert = (this.asset.documents || []).find(d => d.type === 'epb' && d.certificate.type === 'CEPC-CERT' && d.status === 'active' && d.score);

      if (!cert) return null;

      return cert.score;
    },
    wiredScore() {
      const cert = (this.asset.documents || []).find(d => d.type === 'wiredScore' && d.status === 'active' && d.score);

      if (!cert) return null;

      return cert.score;
    },
    breeamScore() {
      const cert = (this.asset.documents || []).find(d => d.type === 'breeam' && d.status === 'active' && d.score);

      if (!cert) return null;

      return cert.score;
    }
  }
};
</script>
