var render = function render(){
  var _vm$carbonIntensity, _vm$carbonIntensity2, _vm$carbonIntensity3, _vm$carbonIntensity4, _vm$carbonIntensity5, _vm$carbonIntensity6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [_vm._v("Floor Areas")]), _c('p', [_vm._v(" Distribution of floor areas between landlord and tenant accounts (not including sub-meters). Any accounts that do not have a valid floor area have been excluded from the calculations. ")]), _c('div', {
    staticClass: "row mb-5"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm.landlordTenantData ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1 bg-secondary",
    attrs: {
      "value": _vm.landlordTenantData.totals.landlord,
      "precision": "0",
      "icon-class": "fa-crown",
      "description": "Landlord",
      "units": "m2",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['landlord-tenant'],
      "error": _vm.errorAction.getAnalytics['landlord-tenant'],
      "is-report": "",
      "inverse-color": "",
      "hide-prev-value": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-4"
  }, [_vm.landlordTenantData ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.landlordTenantData.totals.tenant,
      "precision": "0",
      "icon-class": "fa-house-user",
      "description": "Tenant",
      "units": "m2",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['landlord-tenant'],
      "error": _vm.errorAction.getAnalytics['landlord-tenant'],
      "is-report": "",
      "inverse-color": "",
      "hide-prev-value": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-4"
  }, [_vm.landlordTenantData ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1 bg-info",
    attrs: {
      "value": _vm.landlordTenantData.totals.total,
      "precision": "0",
      "icon-class": "fa-square-dashed",
      "description": "Total",
      "units": "m2",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['landlord-tenant'],
      "error": _vm.errorAction.getAnalytics['landlord-tenant'],
      "is-report": "",
      "inverse-color": "",
      "hide-prev-value": ""
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "mb-5"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Landlord/Tenant Split")]), _vm.floorAreaChart ? _c('apexchart', {
    attrs: {
      "height": "100",
      "options": _vm.floorAreaChart.options,
      "series": _vm.floorAreaChart.series
    }
  }) : _vm._e(), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.types, function (supplyType) {
    return _c('div', {
      key: supplyType,
      staticClass: "col-6"
    }, [_c('h4', {
      staticClass: "mb-2"
    }, [_vm._v(_vm._s(_vm._f("capitalize")(supplyType)) + " Consumption")]), _vm.consumptionChart(supplyType) ? _c('p', {
      staticClass: "mb-0"
    }, [_c('strong', [_vm._v("Landlord:")]), _vm._v(" " + _vm._s(_vm._f("utilityUnit")(_vm.consumptionChart(supplyType).series[0].data[0], supplyType)) + " ")]) : _vm._e(), _vm.consumptionChart(supplyType) ? _c('p', {
      staticClass: "mb-0"
    }, [_c('strong', [_vm._v("Tenant:")]), _vm._v(" " + _vm._s(_vm._f("utilityUnit")(_vm.consumptionChart(supplyType).series[1].data[0], supplyType)) + " ")]) : _vm._e(), _vm.consumptionChart(supplyType) ? _c('apexchart', {
      attrs: {
        "height": "100",
        "options": _vm.consumptionChart(supplyType).options,
        "series": _vm.consumptionChart(supplyType).series
      }
    }) : _c('div', {
      staticClass: "mt-2 font-w600 text-muted"
    }, [_vm._v("No " + _vm._s(supplyType) + " data found")])], 1);
  }), 0)], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('h3', [_vm._v("Carbon Intensity")]), _vm._m(0), _c('div', {
    staticClass: "row mb-5"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm.carbonIntensity() ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1 bg-secondary",
    attrs: {
      "value": (_vm$carbonIntensity = _vm.carbonIntensity()) === null || _vm$carbonIntensity === void 0 ? void 0 : _vm$carbonIntensity.landlord,
      "prev-value": (_vm$carbonIntensity2 = _vm.carbonIntensity(true)) === null || _vm$carbonIntensity2 === void 0 ? void 0 : _vm$carbonIntensity2.landlord,
      "precision": "2",
      "icon-class": "fa-crown",
      "description": "Landlord",
      "units": "CO2e/m2",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-4"
  }, [_vm.carbonIntensity ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$carbonIntensity3 = _vm.carbonIntensity()) === null || _vm$carbonIntensity3 === void 0 ? void 0 : _vm$carbonIntensity3.tenant,
      "prev-value": (_vm$carbonIntensity4 = _vm.carbonIntensity(true)) === null || _vm$carbonIntensity4 === void 0 ? void 0 : _vm$carbonIntensity4.tenant,
      "precision": "2",
      "icon-class": "fa-house-user",
      "description": "Tenant",
      "units": "CO2e/m2",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-4"
  }, [_vm.carbonIntensity ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1 bg-info",
    attrs: {
      "value": (_vm$carbonIntensity5 = _vm.carbonIntensity()) === null || _vm$carbonIntensity5 === void 0 ? void 0 : _vm$carbonIntensity5.total,
      "prev-value": (_vm$carbonIntensity6 = _vm.carbonIntensity(true)) === null || _vm$carbonIntensity6 === void 0 ? void 0 : _vm$carbonIntensity6.total,
      "precision": "2",
      "icon-class": "fa-square-dashed",
      "description": "Total",
      "units": "CO2e/m2",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['asset-report-emissions-prev'],
      "error": _vm.errorAction.getAnalytics['asset-report-emissions'],
      "is-report": ""
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" The CO2e m"), _c('sup', [_vm._v("2")]), _vm._v(" values for landlord and tenant consumption data, taking into account the floor area. Consumption on accounts with no floor area has been excluded. ")]);

}]

export { render, staticRenderFns }