var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Page', [_c('h2', [_vm._v("Projects Summary")]), _c('p', [_vm._v(" The latest updates from any projects being undertaken or completed at this asset between " + _vm._s(_vm._f("date")(_vm.period.startDate, 'Do MMMM YYYY')) + " and " + _vm._s(_vm._f("date")(_vm.period.endDate, 'Do MMMM YYYY')) + ". ")]), _c('div', {
    staticClass: "row mb-5"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm.projects() ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.projects().filter(function (p) {
        return p.status === 'pending';
      }).length,
      "prev-value": _vm.projects(true).filter(function (p) {
        return p.status === 'pending';
      }).length,
      "precision": "0",
      "icon-class": "fa-ruler-triangle",
      "description": "Pending",
      "units": "Projects",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['projects'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['projects-prev'],
      "error": _vm.errorAction.getAnalytics['projects'],
      "is-report": "",
      "inverse-color": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-4"
  }, [_vm.projects() ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.projects().filter(function (p) {
        return p.status === 'active';
      }).length,
      "prev-value": _vm.projects(true).filter(function (p) {
        return p.status === 'active';
      }).length,
      "precision": "0",
      "icon-class": "fa-traffic-cone",
      "description": "Active",
      "units": "Projects",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['projects'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['projects-prev'],
      "error": _vm.errorAction.getAnalytics['projects'],
      "is-report": "",
      "inverse-color": ""
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-4"
  }, [_vm.projects() ? _c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.projects().filter(function (p) {
        return p.status === 'completed';
      }).length,
      "prev-value": _vm.projects(true).filter(function (p) {
        return p.status === 'completed';
      }).length,
      "precision": "0",
      "icon-class": "fa-check",
      "description": "Completed",
      "units": "Projects",
      "comparison-period": "period",
      "value-loading": _vm.loadingAction.getAnalytics['projects'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['projects-prev'],
      "error": _vm.errorAction.getAnalytics['projects'],
      "is-report": "",
      "inverse-color": ""
    }
  }) : _vm._e()], 1)]), _c('h3', [_vm._v("List of projects")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Start Date")]), _c('th', [_vm._v("End Date")]), _c('th', [_vm._v("Tasks Progress")])])]), _c('tbody', _vm._l(_vm.projects().slice(0, _vm.projectsFirstPage), function (project) {
    return _c('tr', {
      key: project._id
    }, [_c('td', [_c('div', {
      staticClass: "mb-1 font-w600"
    }, [_vm._v(_vm._s(project.name))]), _c('div', [_vm._v(_vm._s(project.description.length > 100 ? project.description.trim().slice(0, 100) + '...' : project.description))])]), _c('td', [_c('span', {
      staticClass: "font-w600",
      class: {
        'text-success': project.status === 'completed',
        'text-warning': project.status === 'active',
        'text-muted': project.status === 'pending'
      }
    }, [_vm._v(_vm._s(_vm._f("capitalize")(project.status)))])]), _c('td', [_vm._v(_vm._s(_vm._f("date")(project.startDate)))]), _c('td', [project.isOverdue ? _c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(_vm._f("date")(project.endDate)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm._f("date")(project.endDate)) + " ")])]), _c('td', {
      staticClass: "text-right font-w600",
      staticStyle: {
        "width": "200px"
      }
    }, [project.tasks.every(function (t) {
      return t.status === 'completed';
    }) ? _c('span', {
      staticClass: "text-success"
    }, [_vm._v(" 100% "), _c('i', {
      staticClass: "fa fa-circle-check"
    })]) : _c('span', {
      staticClass: "text-muted"
    }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(project.tasks.filter(function (t) {
      return t.status === 'completed';
    }).length / project.tasks.length * 100)) + "% (" + _vm._s(project.tasks.filter(function (t) {
      return t.status !== 'completed';
    }).length) + " incomplete) ")])])]);
  }), 0)])]), _vm._l(_vm.projectPages, function (page, index) {
    return _c('Page', {
      key: index
    }, [_c('h3', [_vm._v("List of projects cont.")]), _c('table', {
      staticClass: "table"
    }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Start Date")]), _c('th', [_vm._v("End Date")]), _c('th', [_vm._v("Tasks Progress")])])]), _c('tbody', _vm._l(page, function (project) {
      return _c('tr', {
        key: project._id
      }, [_c('td', [_c('div', {
        staticClass: "mb-1 font-w600"
      }, [_vm._v(_vm._s(project.name))]), _c('div', [_vm._v(_vm._s(project.description.length > 100 ? project.description.trim().slice(0, 100) + '...' : project.description))])]), _c('td', [_c('span', {
        staticClass: "font-w600",
        class: {
          'text-success': project.status === 'completed',
          'text-warning': project.status === 'active',
          'text-muted': project.status === 'pending'
        }
      }, [_vm._v(_vm._s(_vm._f("capitalize")(project.status)))])]), _c('td', [_vm._v(_vm._s(_vm._f("date")(project.startDate)))]), _c('td', [project.isOverdue ? _c('span', {
        staticClass: "text-danger"
      }, [_vm._v(" " + _vm._s(_vm._f("date")(project.endDate)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm._f("date")(project.endDate)) + " ")])]), _c('td', {
        staticClass: "text-right font-w600",
        staticStyle: {
          "width": "200px"
        }
      }, [project.tasks.every(function (t) {
        return t.status === 'completed';
      }) ? _c('span', {
        staticClass: "text-success"
      }, [_vm._v(" 100% "), _c('i', {
        staticClass: "fa fa-circle-check"
      })]) : _c('span', {
        staticClass: "text-muted"
      }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(project.tasks.filter(function (t) {
        return t.status === 'completed';
      }).length / project.tasks.length * 100)) + "% (" + _vm._s(project.tasks.filter(function (t) {
        return t.status !== 'completed';
      }).length) + " incomplete) ")])])]);
    }), 0)])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }